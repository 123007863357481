import React, {useContext} from 'react'
import {MyContext} from '../../login/MyContext'

function Shoping() {

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    if(isAuth)
    {
    return (
            <div className="content-wrapper">
    
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          
          
        </div>
      </div>
    </div>
   

   
    <section className="content">
      <div className="container-fluid">
        
      <div className="row" > 

        <div class="col-lg-3 col-12">

<div class="small-box bg-info">
<div class="inner">
<center><h5>1 PROGRAMMER ADICIONAL - PARA UN PROYECTO</h5></center>
<hr/>
<p>Minimiza 20 días de desarrollo   <br/></p>

</div>
<div >

</div>
<a href="#" class="small-box-footer">
$500.000 COP / <i class="fas fa-arrow-circle-up"></i>
<i class="fa fa-shopping-cart " style={{color:"#FAC37E"}}> </i></a>
</div>

</div>


<div class="col-lg-3 col-12">

<div class="small-box bg-info">
<div class="inner">
<center><h5>2 PROGRAMMER ADICIONAL - PARA UN PROYECTO</h5></center>
<hr/>
<p>Minimiza 35 días de desarrollo <br/></p>
</div>
<div >

</div>
<a href="#" class="small-box-footer">
$700.000 COP / <i class="fas fa-arrow-circle-up"></i>
<i class="fa fa-shopping-cart " style={{color:"#FAC37E"}}> </i></a>
</div>

</div>



<div class="col-lg-3 col-12">

<div class="small-box bg-info">
<div class="inner">
<center><h5>3 PROGRAMMER ADICIONAL - PARA UN PROYECTO</h5></center>
<hr/>
<p><p>Minimiza 50 días de desarrollo <br/></p></p>
</div>
<div >

</div>
<a href="#" class="small-box-footer">
$1.000.000 COP / <i class="fas fa-arrow-circle-up"></i>
<i class="fa fa-shopping-cart " style={{color:"#FAC37E"}}> </i></a>
</div>

</div>




<div class="col-lg-3 col-12">

<div class="small-box bg-info">
<div class="inner">
<center><h5>4 PROGRAMMER ADICIONAL - PARA UN PROYECTO</h5></center>
<hr/>
<p>Minimiza 80 días de desarrollo <br/></p>
</div>
<div >

</div>
<a href="#" class="small-box-footer">
$1.800.000 COP / <i class="fas fa-arrow-circle-up"></i>
<i class="fa fa-shopping-cart " style={{color:"#FAC37E"}}> </i></a>
</div>

</div>


        
      <div className="col-md-12">

          <div className="callout callout-info">
              <h5><i className="fa fa-shopping-cart"></i> Shoping</h5>
              
              <p>• El primer paquete aplica para proyectos en desarrollo mayores o igual a 60 días. <br/>
              • El segundo paquete aplica para proyectos en desarrollo mayores o igual a 70 días. <br/>
              • El tercer paquete aplica para proyectos en desarrollo mayores o igual a 90 días. <br/> 
              • El cuarto paquete aplica para proyectos en desarrollo mayores o igual a 120 días.</p>
             
            
             


          
            </div>

            
       
    </div> 

         
        </div> 



        <div className="row" > 

        <div class="col-lg-3 col-12">

<div class="small-box bg-warning">
<div class="inner">
<center><h5>2 PROGRAMMER ADICIONALES - VITALICIA</h5></center>
<hr/>
<p>Minimiza hasta 35 días de desarrollo   <br/></p>
<p>Fecha limite promoción: <b>Lunes 20 de febrero del 2023 11:59 am </b></p>

</div>
<div >

</div>
<a href="#" class="small-box-footer">
$690.000 COP / <i class="fas fa-arrow-circle-up"></i>
<i class="fa fa-shopping-cart " style={{color:"black"}}> </i></a>
</div>

</div>


<div class="col-lg-3 col-12">

<div class="small-box bg-warning">
<div class="inner">
<center><h5>3 PROGRAMMER ADICIONALES - VITALICIA</h5></center>
<hr/>
<p>Minimiza hasta 50 días de desarrollo <br/></p>
<p>Fecha limite promoción: <b>Lunes 20 de febrero del 2023 11:59 am </b></p>
</div>
<div >

</div>
<a href="#" class="small-box-footer">
$990.000 COP / <i class="fas fa-arrow-circle-up"></i>
<i class="fa fa-shopping-cart " style={{color:"black"}}> </i></a>
</div>

</div>



<div class="col-lg-3 col-12">

<div class="small-box bg-warning">
<div class="inner">
<center><h5>5 PROGRAMMER ADICIONALES - VITALICIA</h5></center>
<hr/>
<p><p>Minimiza hasta 70 días de desarrollo <br/></p></p>
<p>Fecha limite promoción: <b>Lunes 20 de febrero del 2023 11:59 am </b></p>
</div>
<div >

</div>
<a href="#" class="small-box-footer">
$1.300.000 COP / <i class="fas fa-arrow-circle-up"></i>
<i class="fa fa-shopping-cart " style={{color:"black"}}> </i></a>
</div>

</div>




<div class="col-lg-3 col-12">

<div class="small-box bg-warning">
<div class="inner">
<center><h5>10 PROGRAMMER ADICIONALES - VITALICIA</h5></center>
<hr/>
<p>Minimiza hasta 170 días de desarrollo <br/></p>
<p>Fecha limite promoción: <b>Lunes 20 de febrero del 2023 11:59 am </b></p>
</div>
<div >

</div>
<a href="#" class="small-box-footer">
$1.800.000 COP / <i class="fas fa-arrow-circle-up"></i>
<i class="fa fa-shopping-cart " style={{color:"black"}}> </i></a>
</div>

</div>



         
        </div> 
      

        

      </div>
    </section>
    
  </div>
    )
    }

    else if(showLogin){
        return "";
    }
    
    }

export default Shoping
