import React, {useContext} from 'react'
import {MyContext} from '../../login/MyContext'

function Productos() {

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    if(isAuth)
    {
    return (
            <div className="content-wrapper">
    
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          
          
        </div>
      </div>
    </div>
   

   
    <section className="content">
      <div className="container-fluid">
        
      <div className="row" > 

        <div class="col-lg-3 col-12">

<div class="small-box bg-info">
<div class="inner">
<center><h5>Servidor Cloud Profesional</h5></center>
<hr/>
<p>- Ram 10 GB : <b><b style={{color:'#FAC37E'}}>USADO 0.5% </b> </b><br/>
   - Procesador 8 cores <b style={{color:'#FAC37E'}}>USADO 1.8% </b> <br/>
   - SSD 800GB <b style={{color:'#FAC37E'}}>USADO 5.9% </b><br/>
   - Banda ancha 2 TB <b style={{color:'#FAC37E'}}>USADO 5.8% </b><br/>
   - Nodos ilimitados  <br/>
   - Socket ilimitados   <br/>
   - Dominios .com 32  <b style={{color:'#FAC37E'}}>USADO 5 DE 32 </b><br/>
   - Dominios gratis - 50  <b style={{color:'#FAC37E'}}>USADO 1 DE 50 </b></p>

</div>
<div >

</div>

</div>

</div>


<div class="col-lg-3 col-12">

<div class="small-box bg-info">
<div class="inner">
<center><h5>PROGRAMMER</h5></center>
<hr/>
<p>14 PROGRAMER - <b style={{color:'#FAC37E'}}>LICENCIA VITALICIA </b><br/> <br/></p>
</div>
<div >

</div>
{/*<a href="#" class="small-box-footer">
$700.000 COP / <i class="fas fa-arrow-circle-up"></i>
<i class="fa fa-shopping-cart " style={{color:"#FAC37E"}}> </i></a> */}
</div>

</div>



<div class="col-lg-3 col-12">

<div class="small-box bg-info">
<div class="inner">
<center><h5>PROMOCIONES</h5></center>
<hr/>
<p>            - Convertir paginas web creadas en apps <b style={{color:'#FAC37E'}}>LICENCIA VITALICIA</b> <br/>
              <br/>- Convertir paginas web ajenas en apps android y/o IOS <b style={{color:'#FAC37E'}}>LICENCIA VITALICIA</b> <br/>
              <br/>- Reparar paginas web obsoletas <b style={{color:'#FAC37E'}}>LICENCIA VITALICIA</b> <br/>
              <br/>- Crear logos IA de mejor calidad <b style={{color:'#FAC37E'}}>LICENCIA VITALICIA</b> <br/>
              <br/>- El servidor va ganando recursos con IA <b style={{color:'#FAC37E'}}>LICENCIA VITALICIA</b></p>
</div>
<div >

</div>
{/*<a href="#" class="small-box-footer">
$1.000.000 COP / <i class="fas fa-arrow-circle-up"></i>
<i class="fa fa-shopping-cart " style={{color:"#FAC37E"}}> </i></a> */}
</div>

</div>




<div class="col-lg-3 col-12">

<div class="small-box bg-info">
<div class="inner">
<center><h5>MARKETING</h5></center>
<hr/>
<p>1.000.000 de visitas - <b style={{color:'#FAC37E'}}>SE HA GASTADO 85.000 VISITAS</b> <br/></p>
</div>
<div >

</div>

</div>

</div>


        
      <div className="col-md-12">

          <div className="callout callout-info">

          <h5><i className="fa fa-user"></i> Accesos</h5>

          https://ai-od.com - usuario: admin@gmail.com y contraseña: 12345678 <br/>
          https://ia-corp.com - usuario: 12345678 y contraseña: 12345678

          <br/>

          proyectos@embellecetumunicipio.com - clave: Kenryug=00

          <br/> <br/>
              <h5><i className="fa fa-shopping-cart"></i> Productos</h5>
              
              <p>• Servidor Cloud Profesional - <b>Vence el 6 de febrero del 2030,</b> Valor a pagar: <b>$3.200.000 COP</b><br/>
              • Compra de 14 programmer - <b>Licencia vitalicia,</b> Valor a pagado: <b>$1.800.000 COP</b> <br/>
              • Tienda android y ios - <b>Vence el 30 de enero del 2025,</b> Valor a pagar: <b>$400.000 COP</b> <br/><br/>
             <b>• Promociones:</b>
              
              <br/>$300.000 COP convertir paginas web creadas en apps android y/o IOS <b>Licencia vitalicia</b> 
              <br/>$300.000 COP convertir paginas web ajenas en apps android y/o IOS <b>Licencia vitalicia</b> 
              <br/>$300.000 COP reparar paginas web obsoletas <b>Licencia vitalicia</b> 
              <br/>$460.000 COP crear logos IA de mejor calidad <b>Licencia vitalicia</b> 
              <br/>$300.000 COP El servidor va ganando recursos con automantenimiento IA <b>Licencia vitalicia</b> 

              <br/><br/>
              <b>• Renovación dominios:</b>

              <span><br/>fundaciontutic.online - renovación <b>10 de julio del 2025 - $75.000 COP</b>
              <br/>IAplataformas - renovación <b>29 de junio del 2025 - $91.000 COP</b></span>
              <br/>parqueoenvia.com - renovación <b>5 de enero del 2025 - $80.000 COP</b>
              <br/>vrt.be/nl/ - renovación <b>5 de agosto del 2024 - $80.000 COP</b>
              <br/>softciudadano.com - renovación <b>15 de enero del 2025 - $80.000 COP</b>
              <br/>ianeuromarket.com - renovación <b>20 de marzo del 2025 - $80.000 COP</b>
              <br/>embellecetumunicipio.com - renovación <b>20 de mayo del 2025 - $80.000 COP</b>
              <br/>ianeuromarket.com - <b>anulado</b>


              <br/><br/>
              <b>• Marketing :</b>

              <br/> un millon de visitas, facebook, instagram, google y youtube - 11.000.000 COP - se debe 2.750.000 millones - <b>Hasta agotar visitas</b>


</p>
             
            
             


          
            </div>

            
       
    </div> 

         
        </div> 



      {/*  <div className="row" > 

        <div class="col-lg-3 col-12">

<div class="small-box bg-warning">
<div class="inner">
<center><h5>2 PROGRAMMER ADICIONALES - VITALICIA</h5></center>
<hr/>
<p>Minimiza hasta 35 días de desarrollo   <br/></p>
<p>Fecha limite promoción: <b>Lunes 20 de febrero del 2023 11:59 am </b></p>

</div>
<div >

</div>
<a href="#" class="small-box-footer">
$690.000 COP / <i class="fas fa-arrow-circle-up"></i>
<i class="fa fa-shopping-cart " style={{color:"black"}}> </i></a>
</div>

</div>


<div class="col-lg-3 col-12">

<div class="small-box bg-warning">
<div class="inner">
<center><h5>3 PROGRAMMER ADICIONALES - VITALICIA</h5></center>
<hr/>
<p>Minimiza hasta 50 días de desarrollo <br/></p>
<p>Fecha limite promoción: <b>Lunes 20 de febrero del 2023 11:59 am </b></p>
</div>
<div >

</div>
<a href="#" class="small-box-footer">
$990.000 COP / <i class="fas fa-arrow-circle-up"></i>
<i class="fa fa-shopping-cart " style={{color:"black"}}> </i></a>
</div>

</div>



<div class="col-lg-3 col-12">

<div class="small-box bg-warning">
<div class="inner">
<center><h5>5 PROGRAMMER ADICIONALES - VITALICIA</h5></center>
<hr/>
<p><p>Minimiza hasta 70 días de desarrollo <br/></p></p>
<p>Fecha limite promoción: <b>Lunes 20 de febrero del 2023 11:59 am </b></p>
</div>
<div >

</div>
<a href="#" class="small-box-footer">
$1.300.000 COP / <i class="fas fa-arrow-circle-up"></i>
<i class="fa fa-shopping-cart " style={{color:"black"}}> </i></a>
</div>

</div>




<div class="col-lg-3 col-12">

<div class="small-box bg-warning">
<div class="inner">
<center><h5>10 PROGRAMMER ADICIONALES - VITALICIA</h5></center>
<hr/>
<p>Minimiza hasta 170 días de desarrollo <br/></p>
<p>Fecha limite promoción: <b>Lunes 20 de febrero del 2023 11:59 am </b></p>
</div>
<div >

</div>
<a href="#" class="small-box-footer">
$1.800.000 COP / <i class="fas fa-arrow-circle-up"></i>
<i class="fa fa-shopping-cart " style={{color:"black"}}> </i></a>
</div>

</div>



         
        </div> */}
      

        

      </div>
    </section>
    
  </div>
    )
    }

    else if(showLogin){
        return "";
    }
    
    }

export default Productos
